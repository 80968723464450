<template>
  <tm-popup
    :fullscreen="true"
    :submit="onSubmit"
    :submit-button-disabled="submitButtonDisabled"
    ref="popup"
    title="تفاصيل المنتج الفرعي"
  >
    <vs-row>
      <vs-col
        vs-align="right"
        vs-justify="start"
        vs-type="flex"
        vs-w="12"
      >
        <vs-row>
          <vs-card
            :key="subProduct.id"
            v-for="(subProduct,subProductIndex) in subProducts"
          >
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  :options="products"
                  :reduce="name => name.id"
                  class="w-full"
                  label="shownName"
                  name="المنتج"
                  v-model="product_id"
                  validation-rules="required"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  class="w-full"
                  name="الاسم"
                  placeholder="الاسم"
                  type="text"
                  v-model="subProduct.name"
                  validation-rules="required"
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  class="w-full"
                  name="السعر"
                  placeholder="السعر"
                  type="number"
                  v-model="subProduct.price"
                  validation-rules="required"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  class="w-full"
                  name="الكمية"
                  placeholder="الكمية"
                  type="number"
                  v-model="subProduct.qty"
                  validation-rules="required|min_value:0"
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  class="w-full"
                  name="وقت تجهيز المنتج"
                  placeholder="وقت تجهيز المنتج"
                  type="number"
                  v-model="subProduct.process_time"
                  validation-rules="required|min_value:1"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-input
                  class="w-full"
                  name="الباركود"
                  placeholder="الباركود"
                  type="text"
                  v-model="subProduct.barcode"
                  validation-rules="required"
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  :options="packageSizes"
                  :reduce="size => size.id"
                  class="w-full"
                  label="shownName"
                  name="حجم التغليف"
                  v-model="subProduct.package_size_id"
                  validation-rules="required"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  :options="['Available','NotAvailable']"
                  :reduce="option => option"
                  class="w-full mb-6"
                  name="الحالة"
                  v-model="subProduct.stock_status"
                  validation-rules="required"
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col  w-full">
                <tm-input
                  class="w-full"
                  name="التفاصيل"
                  placeholder="التفاصيل"
                  type="text"
                  v-model="subProduct.desc"
                  validation-rules=""
                />
              </div>
            </div>
            <div class="vx-row mi-format-align-justify">
              <div class="vx-col w-full">
                <tm-select
                  :multiple="true"
                  :options="tags"
                  :reduce="name => name.id"
                  label="name"
                  name="التاغ (اختر تاغ واحد أو أكثر)"
                  v-model="subProduct.tags"
                  validation-rules="required"
                />
              </div>
            </div>

            <div class="vx-row mi-format-align-justify mb-5">
              <div class="vx-col sm:w-1/2 w-full">
                <tm-select
                  :options="variants"
                  @input="selectVariantKey"
                  label="name"
                  :reduce="option => option"
                  name="اسم الخاصية"
                  v-model="variantKey"
                  validation-rules="required"
                />
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-3">
                <tm-select
                  :options="variantValues"
                  label="value"
                  :reduce="option => option"
                  name="قيمة الخاصية"
                  v-model="variantValue"
                  validation-rules="required"
                />
              </div>
            </div>
            <vs-row class="mb-5">
              <div
                :key="i"
                v-for="(variant,i) in subProduct.variants"
              >
                <vs-chip color="primary">
                  {{ variant.variant_key }}
                </vs-chip>
                <vs-chip
                  @click="deleteSubproductVariants(subProductIndex,i)"
                  closable
                  color="primary"
                  transparent
                >
                  {{ variant.variant_value }}
                </vs-chip>
              </div>
            </vs-row>
            <vs-row>
              <vs-col
                vs-align="right"
                vs-justify="start"
                vs-lg="6"
                vs-sm="4"
                vs-type="flex"
                vs-xs="12"
              >
                <ValidationProvider
                  name="إضافة خاصية"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    hidden
                    v-model="subProduct.isVariantsExist"
                  >
                  <vs-button
                    @click="addVariantValue(subProductIndex)"
                    color="primary"
                    icon="icon-plus"
                    icon-pack="feather"
                    size="small"
                    type="filled"
                  >
                    اضافة الخاصية
                  </vs-button>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </vs-col>
            </vs-row>
            <div class="vx-row mi-format-align-justify mb-5">
              <div class="vx-col w-full">
                <h4 class="my-5">
                  الصور
                </h4>
                <file-pond-component
                  :files="subProduct.images.map(image => image.image_url)"
                  :multiple="true"
                  @fileDeleted="(fileIndex) => fileDeleted(subProductIndex, fileIndex)"
                  @fileUploaded="(files) => fileUploaded(files, subProductIndex)"
                  project="website"
                  ref="fileUpload"
                  :rules="{required: false}"
                />
              </div>
            </div>
            <vs-col
              v-if="isManySubProduct"
              vs-align="left"
              vs-justify="end"
              vs-lg="12"
              vs-sm="4"
              vs-type="flex"
              vs-xs="12"
            >
              <vs-button
                @click="deleteSubproduct(subProductIndex)"
                color="danger"
                icon="icon-x"
                icon-pack="feather"
                size="small"
                type="gradient"
              >
                حذف المنتج الفرعي
              </vs-button>
            </vs-col>
            <vs-col
              class="mb-5"
              vs-align="right"
              vs-justify="start"
              vs-type="flex"
              vs-w="12"
            >
              <vs-checkbox
                class="inline-flex"
                v-model="subProduct.allow_upload_image"
              >
                السماح برفع صورة
              </vs-checkbox>
            </vs-col>
          </vs-card>
          <vs-row>
            <vs-col
              vs-align="right"
              vs-justify="start"
              vs-lg="6"
              vs-sm="4"
              vs-type="flex"
              vs-xs="12"
            >
              <vs-button
                @click="addSubproduct"
                color="primary"
                icon="icon-plus"
                icon-pack="feather"
                size="small"
                type="filled"
              >
                اضافة منتج فرعي
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-row>
      </vs-col>
    </vs-row>
  </tm-popup>
</template>

<script>
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import TmSelect from "@/app/shared/shared-components/TmSelect";
import * as websiteFactory from '@/app/website/website-repositories/websiteRepositoryFactory';
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent";
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import toasted from "@/app/shared/utilities/toasted";

const subProductRepository = RepositoryFactory.get("subProduct");
const productRepository = RepositoryFactory.get("product");
const tagRepository = websiteFactory.RepositoryFactory.get('tag');
const variantsRepo = RepositoryFactory.get('variantKey');
const packageSizeRepo = RepositoryFactory.get("packageSize");
const configRepo = SharedRepositoryFactory.get('configRepository');

export default {
  name: "StorePopup",
  data() {
    return {
      products: [{id: '', name: '', shownName: ''}],
      product_id: parseInt(this.productId),
      packageSizes: [],
      isManySubProduct: false,
      tags: [],
      subProductsTags: [],
      variants: [],
      variantValue: null,
      disabled: false,
      variantKey: null,
      variantValues: [],
      imagePreview: null,
      isFirstImage: true,
      submitButtonDisabled: false
    };
  },

  props: {
    subProducts: {
      type: Array,
      required: true
    },
    productId: {
      type: String,
      required: true
    }
  },

  methods: {
    onSubmit() {
      this.create();
    },
    fileUploaded(files, subProductIndex) {
      this.submitButtonDisabled = false;
      this.subProducts[subProductIndex].images.push(...files.map(file => ({image_url: file})));
    },
    fileDeleted(subProductIndex, imageIndex) {
      this.subProducts[subProductIndex].images.splice(imageIndex, 1);
    },
    create() {
      this.subProducts.forEach(item => {
        item['product_id'] = this.product_id;
        for (let i = 0; i < item.tags.length; i++) {
          this.subProductsTags.push({tag_id: item.tags[i]});
        }
        item.tags = this.subProductsTags;
        this.subProductsTags = [];
      });
      subProductRepository.store(this.$objectToFD({subProducts: this.subProducts})).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.initFileUpload();
          this.$emit("created", response.data);
        }
      });
    },
    open() {
      this.$refs.popup.open();
    },
    fetchAddSubProductsLimitation() {
      configRepo.getConfigByKey('add_subProducts_limitation').then((data) => {
        this.addSubProductsLimitation = data.value;
      });
    },
    initFileUpload() {
      this.$refs.fileUpload.forEach((fileUploadRef) => fileUploadRef.initValidation());
    },
    getAllProducts() {
      productRepository.getAllProducts().then(response => {
        this.products = response.data;
        this.products.map(p => {
          p.shownName = `${p.category.store.name} - ${p.name}`;
          return p;
        });
      });
    },
    getAllPackageSizes() {
      packageSizeRepo.fetchAllPackageSizes().then((data) => {
        this.packageSizes = data;
        this.packageSizes.map(p => {
          p.shownName = `${p.size}`;
          return p;
        });
      });
    },
    fetchAllTags() {
      tagRepository.fetchAllTags('Ecommerce').then((response) => {
        this.tags = response;
      });
    },
    fetchAllVariants() {
      variantsRepo.getAllVariant().then((response) => {
        this.variants = response;
      });
    },
    addSubproduct() {
      if (this.subProducts.length === this.addSubProductsLimitation)
        toasted.failed(`  لا يمكن إضافة أكثر من ${this.addSubProductsLimitation} منتجات `);
      else {
        this.isManySubProduct = true;
        this.subProducts.push(this.lodash.cloneDeep(this.subProducts[0]));
      }
    },
    deleteSubproduct(index) {
      this.subProducts.splice(index, 1);
    },
    deleteSubproductVariants(index, variantValueIndex) {
      this.subProducts[index].variants.splice(variantValueIndex, 1);
      if (this.subProducts[index].variants.length === 0)
        this.subProducts[index].isVariantsExist = '';
    },
    deleteSubproductImage(index, ImageUrlIndex) {
      this.subProducts[index].images.splice(ImageUrlIndex, 1);
    },
    selectVariantKey(selectedValue) {
      this.variantValue = null;
      this.variantValues = selectedValue.variantValues;
    },
    addVariantValue(index) {
      this.subProducts[index].isVariantsExist = true;
      this.subProducts[index].variants.push(
        {
          variant_value_id: this.variantValue.id,
          variant_value: this.variantValue.value,
          variant_key: this.variantValue.key
        });
    },
    addImageUrl(index) {
      this.isFirstImage = false;
      this.subProducts[index].images.push({image_url: this.subProducts[index].images[0].image_url});
    }
  },
  created() {
    this.getAllProducts();
    this.fetchAllTags();
    this.fetchAllVariants();
    this.getAllPackageSizes();
    this.fetchAddSubProductsLimitation();
  },
  components: {
    TmPopup,
    TmInput,
    TmSelect,
    FilePondComponent
  },
};
</script>

<style>
.customClassToasted {
  z-index: 55000 !important;
}
</style>
