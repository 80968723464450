<template>
  <tm-popup
    :submit="onSubmit"
    title="تفاصيل المنتج الفرعي"
    ref="popup"
    :fullscreen="true"
  >
    <vs-row>
      <vs-card>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="products"
            label="shownName"
            validation-rules="required"
            name="المنتج"
            v-model="product_id"
            :reduce="name => name.id"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="الاسم"
            type="text"
            placeholder="الاسم"
            validation-rules="required"
            v-model="subProduct.name"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="السعر"
            type="number"
            placeholder="السعر"
            validation-rules="required"
            v-model="subProduct.price"
            class="w-full"
          />
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="الكمية"
            type="number"
            placeholder="الكمية"
            validation-rules="required|min_value:0"
            v-model="subProduct.qty"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="وقت تجهيز المنتج"
            type="number"
            placeholder="وقت تجهيز المنتج"
            validation-rules="required|min_value:1"
            v-model="subProduct.process_time"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="الباركود"
            type="text"
            placeholder="الباركود"
            validation-rules=""
            v-model="subProduct.barcode"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="التفاصيل"
            type="text"
            placeholder="التفاصيل"
            validation-rules="required"
            v-model="subProduct.desc"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="packageSizes"
            label="shownName"
            validation-rules="required"
            name="حجم التغليف"
            v-model="subProduct.package_size.size"
            @input="isUpdatePackageing = true"
            :reduce="size => size.id"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            :options="['Available','NotAvailable']"
            :reduce="option => option"
            validation-rules="required"
            name="الحالة"
            v-model="subProduct.stock_status"
            class="w-full mb-6"
          />
        </vs-col>
      </vs-card>

      <vs-col
        vs-type="flex"
        vs-justify="start"
        vs-align="right"
        vs-w="12"
      >
        <vs-checkbox
          class="inline-flex"
          v-model="subProduct.allow_upload_image"
        >
          السماح برفع صورة
        </vs-checkbox>
      </vs-col>
    </vs-row>
  </tm-popup>
</template>

<script>
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import TmPopup from "../../../shared/shared-components/TmPopup";
import TmInput from "../../../shared/shared-components/TmInput";
import TmSelect from "../../../shared/shared-components/TmSelect";

const subProductRepository = RepositoryFactory.get("subProduct");
const productRepository = RepositoryFactory.get("product");
const packageSizeRepo = RepositoryFactory.get("packageSize");

export default {
  name: "UpdatePopup",
  data() {
    return {
      products: [{id: '', name: '', shownName: ''}],
      product_id: parseInt(this.productId),
      packageSizes: [],
      isUpdatePackageing: false
    };
  },

  props: {
    subProduct: {
      type: Object,
      required: true
    },
    productId: {
      type: String,
      required: true
    }
  },

  methods: {
    onSubmit() {
      this.$refs.popup.loading();
      this.subProduct.product_id = this.product_id;
      if (this.isUpdatePackageing)
        this.subProduct.package_size_id = this.subProduct.package_size.size;
      else
        this.subProduct.package_size_id = this.subProduct.package_size.id;
      this.update();
    },
    update() {
      subProductRepository.update(this.subProduct).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.$emit("updated", response.data);
        }
      });
    },
    open() {
      this.isUpdatePackageing = false;
      this.$refs.popup.open();
    },
    getAllProducts() {
      productRepository.getAllProducts().then(response => {
        this.products = response.data;
        this.products.map(p => {
          p.shownName = `${p.category.store.name} - ${p.name}`;
          return p;
        });
      });
    },
    getAllPackageSizes() {
      packageSizeRepo.fetchAllPackageSizes().then((data) => {
        this.packageSizes = data;
        this.packageSizes.map(p => {
          p.shownName = `${p.size}`;
          return p;
        });
      });
    }
  },
  created() {
    this.getAllProducts();
    this.getAllPackageSizes();
  },
  components: {
    TmPopup,
    TmInput,
    TmSelect
  },
};
</script>
